
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseHeader from "@/components/BaseHeader2.vue";
import BaseTextarea from "@/components/BaseTextarea.vue";
import TextButton from "@/components/common/TextButton.vue";
import SelectBox from "@/components/common/SelectBox.vue";
import type { Post } from "@/components/common/post/post";
import { categories } from "@/components/talkroom/constants";

export default defineComponent({
  name: "TalkroomUpload",
  components: {
    SelectBox,
    BaseHeader,
    TextButton,
    BaseTextarea,
  },
  data() {
    const store = useStore();
    return {
      store,
      post: {
        id: -1,
        category: "",
        detail: "",
      } as Post,
      options: this.$_.map(categories, (v) => {
        return { display: v, value: v };
      }),
      isLoading: false,
    };
  },
  computed: {
    btnColor() {
      if (this.isValid) {
        return "var(--blue1-color)";
      }
      return "var(--grey1-color)";
    },
    isValid() {
      if (this.post.category && this.post.detail) {
        return true;
      }
      return false;
    },
  },
  methods: {
    submit() {
      if (!this.isValid) {
        return;
      }
      this.isLoading = true;
      this.$axios
        .patch(`/board/${this.post.id}`, {
          category: this.post.category,
          detail: this.post.detail,
        })
        .then(() => {
          this.$pushGAEvent("talkroom_edit_complete", {
            talkroom_category: this.post.category,
          });
          this.store.dispatch(
            "talkroom/updateSelectedTabName",
            this.post.category
          );
          this.$router.go(-1);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.post.id = Number(this.$route.params.id);
    if (this.post.id > 0) {
      this.$axios.get(`/board/${this.post.id}`).then((result) => {
        const post = result.data as Post;
        this.post.category = post.category;
        this.post.detail = post.detail;
      });
    }
  },
  beforeMount() {
    this.$flutter.callHandler("hideGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
});
